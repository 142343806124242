;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var initSkrollr = function() {
		if (!isMobile()) {
			var s = skrollr.init({
				forceHeight: false,
				smoothScrolling: true,
				smoothScrollingDuration: 200
			});
		} else {
			$('html').addClass('no-skrollr');
		}
	}

	$(document).ready(function() {

		if ( $('input[name="agent_search"]').length > 0 ) {

			var input = $('input[name="agent_search"]').get(0);

			var agentSearch = new Awesomplete(input, {list: document.querySelector("#agent-search-list")});

			input.addEventListener('awesomplete-selectcomplete', function() {
				window.location = $('[data-title="' + this.value + '"]').attr('data-url');
			});  
		}

		initSkrollr();

		$('html').removeClass('no-js').addClass('js');

		$('.post-content a[href$=".jpg"]').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'image',
			gallery: {
				enabled: true
			}
		});

		$('.js-modal').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'inline',
			preloader: false,
			modal: false,
		});

		$(document).on('click', '.popup-modal-dismiss', function(e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

	});

	$('.hero-section__form__header').click(function() {
		$('.hero-section__form__main').slideToggle();
	});

	$('.hero-search__links a[href*="#"]').click(function(e) {
		e.preventDefault();
		var section = $(e.target).attr('href');
		$('html, body').animate({
			scrollTop: ($(section).offset().top - 110)
		}, 600);
	});

})(jQuery);
